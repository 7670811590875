<template>
    <div class="content">
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line">
        <div class="nav">
            <span style="color:rgba(51, 51, 51, .6)">首页</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span>专题专栏</span>
        </div>
        <!-- <div class="nav">
            <div :class="current==index?'nav-item nav-items':'nav-item'" v-for="(item,index) in list" @click="current=index">{{item}}</div>
        </div> -->
        <div class="con">
            <div class="con-item" v-for="(item,index) in conList" >
                <div class="con-title">
                    <div class="con-title-time">{{item.topicYear}}</div>
                    <div class="con-title-name">华版数字版权服务中心专题专栏</div>
                </div>
                <div class="con-list">
                    <div class="con-list-item"  v-for="(items,indexs) in item.topicList" @click="goInfo(items)">
                        <div class="con-list-item-img">
                            <img src="../../assets/img/index/zt-zp-bg.png" alt="" class="imga">
                            <img :src="items.thumbnailUrl" alt="" class="imgs">
                        </div>
                        <div class="con-list-item-name">{{ items.topicName }}</div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {getTopicLists} from '@/api'
  export default {
    name: "Trends",
    components: {

    },
    data() {
      return {
        current:0,
        list:['CPCC中国版权服务年会','CPCC十大中国著作权人年度评选活动'],
        conList:[]
      };
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList(){
            getTopicLists({}).then(res=>{
                // console.log(res)
                if(res.code==200){
                    this.conList=res.data
                }
            })
        },
        goInfo(row){
            this.$router.push({path:'specialInfo',query:{id:row.topicId}})
        },
    }
  };
</script>
<style scoped lang="scss">
.content{
    width: 1400px;
    margin: 0 auto;
    position: relative;

    .nav{
        width: 100%;
        height: 85px;
        font-size: 16px;
        font-family: SimSun;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 20px;
    }
    .top-line{
        width: 100%;
        height: 85px;
        margin-top: 15px;
    }
    // .nav{
    //     display: flex;
    //     flex-wrap: nowrap;
    //     .nav-item{
    //         font-size: 24px;
    //         font-family: FZCuSong-B09;
    //         font-weight: 400;
    //         color: #333333;
    //         margin-right: 60px;
    //     }
    //     .nav-items{
    //         position:relative;
    //     }
    //     .nav-items::after{
    //         content: ' ';
    //         white-space: pre;
    //         width: 260px;
    //         height: 19px;
    //         background: url('../../assets/img/index/zp-line.png');
    //         background-size: 100% 100%;
    //         background-repeat: no-repeat;
    //         position: absolute;
    //         left: 50%;
    //         bottom: -30px;
    //         transform: translateX(-50%);
    //     }
    // }
    .con{
        margin-top: 80px;
        .con-item{
            margin-bottom: 60px;
            .con-title{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                .con-title-time{
                    font-size: 20px;
                    font-family: FZCuSong-B09;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 35px;
                    width: 71px;
                    height: 46px;
                    background-image:url('../../assets/img/index/zp-bg.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    // position: absolute;
                    // left: 0;
                    // bottom: 0;
                }
                .con-title-name{
                    margin-left: 20px;
                    font-size: 20px;
                    font-family: FZCuSong-B09;
                    font-weight: 400;
                    color: #333333;
                    line-height: 35px;
                }
            }

            .con-list{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .con-list-item:hover{
                    transition:all 1000ms ease;
                    transform: scale(1.1);
                }
                .con-list-item{
                    width: 40%;
                    margin-left: 57px;
                    margin-bottom: 30px;
                    transition:all 500ms ease;
                    .con-list-item-img{
                        width: 512px;
                        height: 344px;
                        
                        position: relative;
                        .imga{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            z-index: 9;
                        }
                        .imgs{
                            width: 417px;
                            height: 272px;
                            position: absolute;
                            z-index: 8;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                    }
                    
                    .con-list-item-name{
                        width: 472px;
                        height: 52px;
                        background-image:url('../../assets/img/index/zt-zp-name.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        font-size: 18px;
                        font-family: FZCuSong-B09;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 52px;
                        margin-left: 23px;
                    }
                }
                
            }
        }
    }
}

</style>
  
  